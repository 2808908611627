import React from 'react';
import './About.css';


export default function About() {

  return (
    <div>
      <h1 className="title">about me</h1>
      <h1 className="title">about me</h1>
      <h1 className="title">about me</h1>
      <h1 className="title">about me</h1>
      <h1 className="title">about me</h1>

    </div>
  );
}
