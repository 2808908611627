import React from 'react';
import './Resume.css';


export default function Resume() {

  return (
    <div>
      <h1 className="title">My Resume </h1>

    </div>
  );
}
